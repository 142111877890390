var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Length'),"label-for":"Length"}},[_c('validation-provider',{attrs:{"name":"Length","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"cm"}},[_c('b-form-input',{attrs:{"id":"Length","state":errors.length > 0 ? false:null,"type":"number","placeholder":"","step":"any"},on:{"change":_vm.volumeCalc},model:{value:(_vm.cal.length),callback:function ($$v) {_vm.$set(_vm.cal, "length", $$v)},expression:"cal.length"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Width'),"label-for":"Width"}},[_c('validation-provider',{attrs:{"name":"Width","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"cm"}},[_c('b-form-input',{attrs:{"id":"Width","state":errors.length > 0 ? false:null,"type":"number","placeholder":"","step":"any"},on:{"change":_vm.volumeCalc},model:{value:(_vm.cal.width),callback:function ($$v) {_vm.$set(_vm.cal, "width", $$v)},expression:"cal.width"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Height'),"label-for":"Height"}},[_c('validation-provider',{attrs:{"name":"Height","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"cm"}},[_c('b-form-input',{attrs:{"id":"Height","state":errors.length > 0 ? false:null,"type":"number","placeholder":"","step":"any"},on:{"change":_vm.volumeCalc},model:{value:(_vm.cal.height),callback:function ($$v) {_vm.$set(_vm.cal, "height", $$v)},expression:"cal.height"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Volume'),"label-for":"Volume"}},[_c('validation-provider',{attrs:{"name":"Volume","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"m³"}},[_c('b-form-input',{attrs:{"id":"Volume","state":errors.length > 0 ? false:null,"type":"number","step":"any","placeholder":""},model:{value:(_vm.final_cal.volume),callback:function ($$v) {_vm.$set(_vm.final_cal, "volume", $$v)},expression:"final_cal.volume"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Weight'),"label-for":"Weight"}},[_c('validation-provider',{attrs:{"name":"Weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"kg"}},[_c('b-form-input',{attrs:{"id":"Weight","state":errors.length > 0 ? false:null,"type":"number","step":"any","placeholder":""},model:{value:(_vm.cal.weight),callback:function ($$v) {_vm.$set(_vm.cal, "weight", $$v)},expression:"cal.weight"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Submit'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('Reset'))+" ")])],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',[(_vm.quote)?_c('quote-list',{attrs:{"items":_vm.quote}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }